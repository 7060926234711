import React from "react"
import Layout from "../../components/layout"
import { SalaryPipedriveForm } from "../../components/body/pages/general/pipedriveForm"
import SEO from "../../components/seo"

const SalaryPipedriveFormPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/salary-loan-retail-form/"}
      title="Salary Loan | Refer Your Employer for Salary Loans | Kuda"
      description="Refer us to your employer to get access to a Kuda Salary Loan with quick loan application, approval and disbursement"
    />

    <SalaryPipedriveForm />
  </Layout>
)

export default SalaryPipedriveFormPage
